import React from "react"
import styles from "./contact.module.scss"
import { RiSendPlaneFill } from "react-icons/ri"
import { ValidationError, useForm } from "@formspree/react"

const Contact = () => {
  const [state, handleSubmit] = useForm("mayldabj")

  return (
    <div className={styles.contact}>
      <div
        className={styles.contact__wrap}
        data-scroll
        // data-scroll-sticky
        id="contact"
      >
        <div className={styles.contact__title}>Say hello</div>
        <h3 className={styles.contact__text}>Let's work together.</h3>
      </div>
      <div className={styles.contact__form__wrap} id="contact-form">
        {state.errors.length > 0 ? (
          <p
            style={{
              margin: "2rem",
              textAlign: "center",
              color: "#f4b860",
              fontWeight: "200",
            }}
          >
            Ooops! There was an error. Check the form inputs for possible errors
            or try again later.
          </p>
        ) : null}
        <form onSubmit={handleSubmit}>
          <div className={styles.contact__formgroup}>
            <div className={styles.contact__wrap__input}>
              <input type="text" name="_gotcha" style={{ display: "none" }} />
              <input
                type="text"
                name="name"
                className={styles.contact__formcontrol}
                required
                minLength="3"
                maxLength="40"
                pattern=".*\S.*"
                placeholder=" "
                aria-labelledby="name"
              />
              <label htmlFor="name" id="name" className={styles.contact__label}>
                <span>Name</span>
              </label>
              <ValidationError
                field="name"
                prefix="This field"
                errors={state.errors}
              />
              <span className={styles.focusinput}></span>
            </div>
            <div className={styles.contact__wrap__input}>
              <input
                type="email"
                name="email"
                className={styles.contact__formcontrol}
                required
                placeholder=" "
                aria-labelledby="email"
              />
              <label
                htmlFor="email"
                id="email"
                className={styles.contact__label}
              >
                <span>Email</span>
              </label>
              <ValidationError
                field="email"
                prefix="This field"
                errors={state.errors}
                className={styles.error}
                placeholder=" "
              />
              <span className={styles.focusinput}></span>
            </div>
            <div className={styles.contact__wrap__input}>
              <input
                type="text"
                name="subject"
                className={styles.contact__formcontrol}
                required
                minLength="3"
                maxLength="70"
                placeholder=" "
                pattern=".*\S.*"
                aria-labelledby="subject"
              />
              <label
                htmlFor="subject"
                id="subject"
                className={styles.contact__label}
              >
                <span>Subject</span>
              </label>
              <ValidationError
                field="subject"
                prefix="This field"
                errors={state.errors}
              />
              <span className={styles.focusinput}></span>
            </div>
            <div className={styles.contact__wrap__textarea}>
              <textarea
                name="message"
                rows="5"
                className={styles.contact__formcontrol}
                required
                placeholder=" "
                aria-labelledby="message"
              />
              <label
                htmlFor="message"
                id="message"
                className={styles.contact__label}
              >
                <span>Message</span>
              </label>
              <span className={styles.focusinput}></span>
            </div>
          </div>
          {state.succeeded ? (
            <p className={styles.success}>
              Thanks! <br></br>Your message was successfully sent! <br></br>{" "}
              I'll get back to you shortly <br></br>
            </p>
          ) : (
            <div className={styles.contact__wrap__button}>
              <button type="submit" className={styles.contact__submit}>
                <span className={styles.contact__submit_text}>Submit</span>
                <span className={styles.contact__submit_icon}>
                  <RiSendPlaneFill />
                </span>
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default Contact
