import React from "react"
import styles from "./portfolio.module.scss"
import Project from "../project/project.component"

const Portfolio = () => {
  return (
    <div className={styles.portfolio__center}>
      <div
        className={styles.portfolio__wrap}
        data-scroll
        data-scroll-sticky
        id="portfolio"
      >
        <div className={styles.details__title}>Portfolio</div>
        <h3 className={styles.details__text}>
          Have a look at some of my projects
        </h3>
      </div>
      <div className={styles.portfolio__project} id="projects" data-scroll>
        <Project />
      </div>
    </div>
  )
}

export default Portfolio
