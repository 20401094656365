import React from "react"
import { FaTwitter, FaLinkedinIn, FaGithub } from "react-icons/fa"
import styles from "./social.module.scss"

const Social = () => {
  return (
    <div className={styles.social}>
      <a
        href="https://github.com/ddmunky"
        className={styles.link}
        target="_blank"
        rel="noreferrer"
      >
        <FaGithub className={styles.icon} />
        Github
      </a>
      <a
        href="https://twitter.com/ddmunky"
        className={styles.link}
        target="_blank"
        rel="noreferrer"
      >
        <FaTwitter className={styles.icon} />
        Twitter
      </a>
      <a
        href="https://www.linkedin.com/in/mathieu-derval/"
        className={styles.link}
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn className={styles.icon} />
        Linkedin
      </a>
    </div>
  )
}

export default Social
