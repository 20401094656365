import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Locomotive from "../locomotive/locomotive.component"
import Header from "../header/header.component"
import Hero from "../hero/hero.component"
import Services from "../services/services.component"
import Portfolio from "../portfolio/portfolio.component"
import Contact from "../contact/contact.component"
import Footer from "../footer/footer.component"
import ProgressBar from "../progressBar/progressBar.component"

import "../locomotive/locomotive.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Locomotive />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      {children}
      <ProgressBar />
      <Hero />
      <Services />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
