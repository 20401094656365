import {
  FaCode,
  FaReact,
  FaHtml5,
  FaCss3Alt,
  FaPaintBrush,
  FaGithub,
  FaTerminal,
  FaFigma,
  FaSketch,
  FaDatabase,
  FaRocket,
  FaSlackHash,
  FaTrello,
  FaGlobeAmericas,
  FaClipboardList,
  FaRegImage,
  FaMapSigns,
  FaPalette,
} from "react-icons/fa"
import {
  SiJavascript,
  SiGatsby,
  SiRedux,
  SiSass,
  SiAdobephotoshop,
  SiCodesandbox,
  SiGoogleanalytics,
} from "react-icons/si"

export default {
  title: "Mathieu Derval - Portfolio",
  hero: {
    title: "Mathieu Derval",
  },
  servicesIcons: {
    mainIconsList: [FaCode, FaPaintBrush, SiCodesandbox],
    devIconsList: [
      SiJavascript,
      FaReact,
      SiRedux,
      SiGatsby,
      FaGithub,
      FaTerminal,
      FaHtml5,
      FaCss3Alt,
      SiSass,
    ],

    DesignIconsList: [
      FaFigma,
      FaSketch,
      SiAdobephotoshop,
      FaRegImage,
      FaMapSigns,
      FaPalette,
    ],

    ProductIconsList: [
      FaSlackHash,
      FaDatabase,
      SiGoogleanalytics,
      FaRocket,
      FaTrello,
      FaGlobeAmericas,
      FaClipboardList,
    ],
  },
  footer: {
    socialLinks: [
      {
        index: 1,
        url: "https://twitter.com/ddmunky",
      },
      {
        index: 2,
        url: "https://www.linkedin.com/in/mathieu-derval/",
      },
      {
        index: 3,
        url: "https://github.com/ddmunky",
      },
      {
        index: 4,
        url: "https://500px.com/p/MathieuDerval",
      },
    ],
    copyright: `© ${new Date().getFullYear()} - Mathieu Derval`,
  },
}
