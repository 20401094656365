import React from "react"
import styles from "./sidebar.module.scss"
import {
  FaArrowCircleRight,
  FaHome,
  FaCode,
  FaLayerGroup,
  FaTelegramPlane,
} from "react-icons/fa"
import { Link } from "gatsby"

const data = [
  {
    id: 1,
    text: "home",
    url: "/",
    icon: <FaHome />,
  },
  {
    id: 2,
    text: "services",
    url: "/#services",
    icon: <FaCode />,
  },
  {
    id: 3,
    text: "portfolio",
    url: "/#portfolio",
    icon: <FaLayerGroup />,
  },
  {
    id: 4,
    text: "contact",
    url: "/#contact",
    icon: <FaTelegramPlane />,
  },
]

const Sidebar = ({ toggleSidebar, closeSidebar }) => {
  const tempLinks = data.map(link => {
    return (
      <li key={link.id}>
        <Link to={link.url} onClick={closeSidebar}>
          <span className={styles.icon}>{link.icon}</span>
          {link.text}
          <span className={styles.arrow}>
            <FaArrowCircleRight />
          </span>
        </Link>
      </li>
    )
  })

  return (
    <div
      className={`${
        toggleSidebar
          ? `${styles.sidebar__wrapper} ${styles.sidebar__wrapper_show}`
          : `${styles.sidebar__wrapper}`
      }`}
      data-scroll="false"
    >
      <div className={styles.sidebar__content}>
        <ul className={styles.sidebar__links}>{tempLinks}</ul>
      </div>
    </div>
  )
}

export default Sidebar
