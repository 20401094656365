import React from "react"
import styles from "./scroll.module.scss"

const Scroll = () => {
  const swipe = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="60"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 100 120"
      xmlSpace="preserve"
    >
      <g className={styles.swipedown} fill="#ffffff">
        <path d="M78.482 58.875c-.407 0-1.44.14-2.502.47-.271-3.613-3.299-6.47-6.98-6.47-1.68 0-3.223.594-4.43 1.584-.986-2.673-3.56-4.584-6.57-4.584-1.751 0-3.552.536-5 1.572V33.375c0-4.486-2.621-7.5-6.521-7.5-3.919 0-6.763 3.154-6.763 7.503l.096 42.239-5.76-6.045c-1.55-1.551-3.6-2.405-5.772-2.405s-4.223.854-5.772 2.405c-3.204 3.203-3.211 8.41-.072 11.545.052.059 5.269 5.875 12.712 13.318l1.211 1.215c7.058 7.096 13.152 13.225 25.35 13.225 12.629 0 16.854-6.338 20.1-12.83 3.122-6.244 3.158-30.156 3.158-31.17-.002-3.197-3.031-6-6.485-6zm.641 35.828c-3.056 6.112-6.475 11.172-17.416 11.172-10.95 0-16.366-5.445-23.223-12.34l-1.217-1.221c-7.381-7.381-12.547-13.141-12.655-13.258a5.156 5.156 0 01-1.518-3.679 5.199 5.199 0 011.533-3.685 5.134 5.134 0 017.276-.026l8.33 8.742a1.499 1.499 0 002.586-1.039l-.104-45.997c0-2.076.985-4.5 3.763-4.5 3.268 0 3.521 3.444 3.521 4.5v28a1.5 1.5 0 003 0v-4c0-3.302 2.99-4.5 5-4.5 2.206 0 4 1.794 4 4v5.5a1.5 1.5 0 003 0v-2.5c0-2.206 1.794-4 4-4s4 1.794 4 4v6.5a1.5 1.5 0 003 0v-3.82c.6-.292 1.776-.68 2.482-.68 1.79 0 3.482 1.457 3.482 3 .002 6.719-.51 25.171-2.84 29.831z"></path>
        <path d="M26 19h4v30.375c0 1 1 2 2 2s2-1 2-2V19h4c.606 0 1.153-.178 1.386-.738s.104-1.113-.325-1.542l-6-5.953c-.586-.586-1.535-.562-2.121.023l-6 6.012c-.429.429-.558.893-.325 1.454S25.394 19 26 19zM23.061 62.936l6-6c.429-.429.558-1.261.325-1.822S28.606 54 28 54h-4V24.375c0-1-1-2-2-2s-2 1-2 2V54h-4c-.606 0-1.153.553-1.386 1.113s-.104 1.299.325 1.728l6 6.047c.586.587 1.536.633 2.122.048z"></path>
      </g>
    </svg>
  )

  const scroll = (
    <svg height="30" width="10">
      <circle className={styles.scrolldown_p1} cx="5" cy="15" r="1.8" />
      <circle className={styles.scrolldown_p2} cx="5" cy="15" r="1.8" />
    </svg>
  )

  return (
    <div className={styles.scrolldown_wrapper}>
      <div className={styles.scrolldown}>
        {typeof window !== `undefined`
          ? window.innerWidth <= 769
            ? swipe
            : scroll
          : null}
      </div>
    </div>
  )
}

export default Scroll
