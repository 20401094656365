import React from "react"
import styles from "./footer.module.scss"
import { FaArrowCircleUp } from "react-icons/fa"
import { SocialIcon } from "react-social-icons"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import data from "../../data/data"
import { Link } from "gatsby"

const Footer = ({ IconsProps, footer }) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  return (
    <div ref={ref} className={styles.footer}>
      <div className={styles.footer__body}>
        <motion.div
          className={styles.footer__social}
          initial={{ x: "-500px" }}
          animate={{ x: inView ? "0px" : "-500px" }}
          transition={{ duration: 1 }}
        >
          {footer.socialLinks.map(socialLink => (
            <SocialIcon
              key={socialLink.index}
              url={socialLink.url}
              {...IconsProps}
            />
          ))}
        </motion.div>
        <motion.div
          className={styles.footer__date}
          initial={{ opacity: 0 }}
          animate={{ opacity: inView ? 1 : 0 }}
          transition={{ duration: 2.5 }}
        >
          {footer.copyright}
        </motion.div>
        <motion.div
          className={styles.footer__backtop}
          initial={{ x: "500px" }}
          animate={{ x: inView ? "0px" : "500px" }}
          transition={{ duration: 1 }}
        >
          <Link to="/#">
            Back to top{" "}
            <span>
              <FaArrowCircleUp />
            </span>
          </Link>
        </motion.div>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  IconsProps: {
    style: { height: 40, width: 40, marginRight: 15 },
    bgColor: "rgba(244, 184, 96, 1)",
    target: "_blank",
    rel: "noopener",
  },
  ...data,
}

export default Footer
