import React from "react"

import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

import Scroll from "../scroll/scroll.component"

import data from "../../data/data"
import styles from "./hero.module.scss"

const query = graphql`
  query {
    file(relativePath: { eq: "hero1.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const Hero = ({ hero }) => {
  const data = useStaticQuery(query)

  return (
    <div className={styles.hero__center}>
      <div
        className={styles.hero__wrap}
        data-scroll
        data-scroll-speed="2"
        data-scroll-direction="horizontal"
        data-scroll-position="top"
      >
        <div className={styles.hero__title}>{hero.title}</div>
        <h1 className={styles.hero__text}>
          <span>Hello, my&nbsp;</span>
          <span>name's Mathieu.&nbsp;</span>
          <span>I'm a Javascript&nbsp;</span>
          <span>Developer.</span>
        </h1>
      </div>
      <div className={styles.hero__img}>
        <div className={styles.img__wrap} data-scroll data-scroll-speed="3">
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Website illustration"
          />
        </div>
      </div>
      <div
        className={styles.hero__link}
        data-scroll
        data-scroll-speed="2"
        data-scroll-position="top"
      >
        <Link to="/#services" aria-label="services">
          <Scroll />
        </Link>
      </div>
    </div>
  )
}

Hero.defaultProps = {
  ...data,
}

export default Hero
