import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Social from "../social/social.component"
import Sidebar from "../sidebar/sidebar.component"

import { Squeeze as Hamburger } from "hamburger-react"
import styles from "./header.module.scss"

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="40"
      version="1"
      viewBox="0 0 5120 1600"
    >
      <path
        fill="#f4b860"
        stroke="none"
        d="M3871 1522c-33-7-54-36-401-557-283-425-372-551-391-555-52-13-66-1-149 129-44 69-112 173-150 231-128 196-161 252-158 274 2 11 10 22 18 24 8 1 71-46 140-106 69-59 181-156 249-215s128-107 132-107c11 0 188 273 183 282-12 19-697 570-720 578-14 6-133 10-264 10-195 0-241-3-253-14-21-21-373-552-452-681-37-60-76-120-87-131-10-12-58-80-106-150-87-130-118-158-159-148-14 4-49 48-94 117-110 170-192 296-230 356-19 30-65 103-104 163-38 59-92 144-120 188-134 216-166 261-191 270-30 12-331 13-338 2-2-4 16-42 41-82 82-134 149-243 230-375 100-161 314-511 460-750l109-180h508l27 45c15 25 40 68 54 95 15 28 51 86 80 130s94 150 145 235 110 179 130 210c21 30 66 99 100 153 47 74 67 97 84 97 30 0 38-8 75-75 19-33 48-82 66-110 18-27 72-115 121-195 214-352 304-498 328-532l26-38h490l37 61c21 33 67 107 103 163 36 57 92 147 125 201 33 53 137 220 230 369 197 312 192 308 323 307 126-1 249-52 352-147 68-64 106-129 121-207 28-152-82-348-236-423-56-27-68-29-170-28-88 0-122 5-170 23-74 27-179 88-250 147-30 24-59 44-64 44s-18-15-29-32c-10-18-47-78-81-133-33-55-61-103-61-106 0-11 140-113 201-147 138-78 382-131 536-118 233 19 488 177 585 361 59 113 71 177 65 340-10 240-64 374-211 520-104 103-179 148-321 191-83 25-106 27-285 30-107 1-211-1-229-4z"
      ></path>
    </svg>
  )
}

const Header = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false)

  return (
    <header className={styles.header}>
      <div className={styles.header__center}>
        <div className={styles.header__logo}>
          <Link to="/" className={styles.header__link} aria-label="logo">
            <Logo className={styles.logo} />
          </Link>
        </div>
        <Social />
        <span className={styles.hamburger}>
          <Hamburger
            toggled={toggleSidebar}
            toggle={() => setToggleSidebar(!toggleSidebar)}
            color={`${toggleSidebar ? "#4a5859" : "#fff"}`}
            label="Show menu"
          />
        </span>
      </div>
      <Sidebar
        toggleSidebar={toggleSidebar}
        closeSidebar={() => setToggleSidebar(!toggleSidebar)}
      />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
