import { useEffect } from "react"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import LocomotiveScroll from "locomotive-scroll"

const scroll = {
  // Locomotive Scroll
  // https://github.com/locomotivemtl/locomotive-scroll#instance-options
  container: "#___gatsby",
  options: {
    smooth: true,
    getDirection: true,
    smartphone: {
      smooth: false,
    },
  },
}

const Locomotive = callbacks => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    let locomotiveScroll
    locomotiveScroll = new LocomotiveScroll({
      el: document.querySelector(scroll.container),
      ...scroll.options,
    })
    locomotiveScroll.update()

    // Exposing to the global scope for ease of use.
    window.scroll = locomotiveScroll

    locomotiveScroll.on("scroll", func => {
      // Update `data-direction` with scroll direction.
      document.documentElement.setAttribute("data-direction", func.direction)
    })
    locomotiveScroll.on("scroll", ScrollTrigger.update)

    // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
    ScrollTrigger.scrollerProxy(scroll.container, {
      scrollTop(value) {
        return arguments.length
          ? locomotiveScroll.scrollTo(value, 0, 0)
          : locomotiveScroll.scroll.instance.scroll.y
      },
      // we don't have to define a scrollLeft because we're only scrolling vertically.
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }
      },
      // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
      pinType: document.querySelector(scroll.container).style.transform
        ? "transform"
        : "fixed",
    })

    gsap.from("#services", {
      scrollTrigger: {
        trigger: "#services",
        scroller: scroll.container,
        scrub: true,
        start: "0 100%",
        end: "bottom 45%",
      },
      x: "-150%",
      duration: 3,
      ease: "none",
    })

    gsap.to("#services-card", {
      scrollTrigger: {
        trigger: "#services-card",
        scroller: scroll.container,
        scrub: true,
        start: "top bottom",
        end: "top 60%",
      },
      y: -420,
      duration: 2,
    })

    gsap.from("#portfolio", {
      scrollTrigger: {
        trigger: "#portfolio",
        scroller: scroll.container,
        scrub: true,
        start: "0 100%",
        end: "bottom 45%",
      },
      x: "-120%",
      duration: 3,
      ease: "none",
    })

    gsap.to("#projects", {
      scrollTrigger: {
        trigger: "#projects",
        scroller: scroll.container,
        scrub: true,
        start: "top bottom",
        end: "top 80%",
      },
      y: -450,
      duration: 3,
      ease: "none",
    })

    gsap.from("#contact", {
      scrollTrigger: {
        trigger: "#contact",
        scroller: scroll.container,
        scrub: true,
        start: "0 120%",
        end: "bottom 45%",
      },
      x: "-120%",
      duration: 3,
      ease: "none",
    })

    gsap.to("#contact-form", {
      scrollTrigger: {
        trigger: "#contact-form",
        scroller: scroll.container,
        scrub: true,
        start: "top bottom",
        end: "top 80%",
      },
      y: -380,
      duration: 3,
      ease: "none",
    })

    // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
    ScrollTrigger.addEventListener("refresh", () => locomotiveScroll.update())

    // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
    ScrollTrigger.refresh()

    return () => {
      if (locomotiveScroll) locomotiveScroll.destroy()
    }
  }, [callbacks])

  return null
}

export default Locomotive
