import React from "react"

import { renderRichText } from "gatsby-source-contentful/rich-text"
import styles from "./serviceCard.module.scss"

const ServiceCard = ({ icons, index, ...service }) => {
  const Icon = icons.mainIconsList[index]

  return (
    <div key={service.contentful_id} className={styles.card}>
      {<Icon className={styles.card__icon} />}

      <h3>{service.serviceTitle}</h3>

      <div className={styles.underline}></div>

      {renderRichText(service.serviceDesc)}

      {service.serviceStack.map((stack, index) => {
        let SingleIcon
        if (service.contentful_id === "6sONq6H07SEWZQnd0fy7UG") {
          SingleIcon = icons.devIconsList[index]
        } else if (service.contentful_id === "20wnIwPZuPgNx8BmcL9Wyh") {
          SingleIcon = icons.DesignIconsList[index]
        } else if (service.contentful_id === "3doaYLLac8jYa9TaTwV26q") {
          SingleIcon = icons.ProductIconsList[index]
        }
        return <SingleIcon key={index} className={styles.card__icon} />
      })}
    </div>
  )
}

export default ServiceCard
