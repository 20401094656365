import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import ServiceCard from "../serviceCard/serviceCard.component"
import data from "../../data/data"
import styles from "./services.module.scss"

const query = graphql`
  query {
    s: allContentfulService(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: createdAt, order: ASC }
    ) {
      nodes {
        contentful_id
        serviceDesc {
          raw
        }
        serviceIcon
        serviceStack
        serviceTitle
      }
    }
  }
`

const Services = ({ servicesIcons }) => {
  const data = useStaticQuery(query)
  const services = data.s.nodes

  return (
    <div className={styles.services__center}>
      <div
        className={styles.services__wrap}
        data-scroll
        data-scroll-sticky
        id="services"
      >
        <div className={styles.details__title}>What I do</div>
        <h2 className={styles.details__text}>
          <div>I enjoy creating delightful, </div>
          <div>human-centered digital experiences.</div>
        </h2>
      </div>
      <div className={styles.services__card} id="services-card" data-scroll>
        {services.map((service, index) => {
          return (
            <ServiceCard
              key={service.contentful_id}
              index={index}
              icons={servicesIcons}
              {...service}
            />
          )
        })}
      </div>
    </div>
  )
}

Services.defaultProps = {
  ...data,
}

export default Services
