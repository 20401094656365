import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Img from "gatsby-image"
import { FaGithubSquare, FaExternalLinkAlt, FaLink } from "react-icons/fa"
import styles from "./project.module.scss"

const query = graphql`
  query {
    p: allContentfulProject(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: projectId, order: DESC }
    ) {
      nodes {
        projectTitle
        projectUrl
        projectGithubUrl
        projectStack
        projectIllustration {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        projectDesc {
          raw
        }
        contentful_id
      }
    }
  }
`

const options = {
  renderNode: {},
}

const Project = () => {
  const data = useStaticQuery(query)
  const projects = data.p.nodes

  return projects.map(project => (
    <div key={project.contentful_id} className={styles.project}>
      {data && (
        <Img
          fluid={project.projectIllustration.fluid}
          className={styles.project__img}
        />
      )}
      <div className={styles.project__wrap}>
        <div className={styles.project__wrap_front}>
          <h3>
            <a
              href={project.projectUrl}
              target="_blank"
              rel="noreferrer"
              aria-label={project.projectTitle}
            >
              {project.projectTitle} -{" "}
              <FaExternalLinkAlt className={styles.project__icon} />
            </a>
          </h3>
          <div className={styles.underline}></div>
          <div className={styles.project__desc}>
            {renderRichText(project.projectDesc, options)}
          </div>
          <div className={styles.underline}></div>
          <div className={styles.project__stack}>
            {project.projectStack.map((item, index) => (
              <span key={index}>{item}</span>
            ))}
          </div>
          <div className={styles.project__links}>
            {project.projectGithubUrl && (
              <a
                href={project.projectGithubUrl && project.projectGithubUrl}
                target="_blank"
                rel="noreferrer"
                aria-label={project.projectTitle}
              >
                <FaGithubSquare className={styles.project__icon} />
              </a>
            )}
            {/* <a
              href={project.projectUrl}
              target="_blank"
              rel="noreferrer"
              aria-label={project.projectTitle}
            >
              <FaLink className={styles.project__icon} />
            </a> */}
          </div>
        </div>
        <div className={styles.project__wrap_back}></div>
      </div>
    </div>
  ))
}

export default Project
